import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';

const BridgePartners = () => {
  return (
    <Layout>
      <Seo
        title="Bridge Partners | Bridge"
        description="Bridge the gap in patient care. Partner with BridgeInteract: industry-leading patient engagement tech for happy patients, cost savings, and improved outcomes."
        canonical="/bridge-partners/"
      />
      <Section variant="hero">
        <Container>
          <Row>
            <Col>
              <h1>Bridge Partners</h1>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <p>
                We’re devoted to growing our partnerships with leading companies
                specialized in software development to make sure that we provide
                our clients with the best user experience.
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              lg={4}
              className="text-center d-inline-flex align-items-center px-5 py-3 py-lg-0"
            >
              <StaticImage src="../images/mwe-logo.png" alt="MWE" />
            </Col>
            <Col lg={8}>
              <h2>Medical Web Experts</h2>
              <p>
                Medical Web Experts is a leading provider of HIPAA-compliant
                enterprise healthcare solutions including mobile app
                development, custom portals, interface development, and web
                development.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col
              lg={4}
              className="text-center d-inline-flex align-items-center px-5 py-3 py-lg-0"
            >
              <StaticImage
                src="../images/expert-demand-generation.png"
                alt="Expert Demand Generation"
              />
            </Col>
            <Col lg={8}>
              <h2>Expert Demand Generation</h2>
              <p>
                Expert Demand Generation is a healthcare business and software
                vendor marketing company offering both digital and outbound
                marketing services.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col
              lg={4}
              className="text-center d-inline-flex align-items-center px-5 py-3 py-lg-0"
            >
              <StaticImage
                src="../images/medical-advantage-tdc-group.png"
                alt="Medical Advantage TDC Group"
              />
            </Col>
            <Col lg={8}>
              <h2>Medical Advantage</h2>
              <p>
                Medical Advantage is a wholly owned subsidiary of The Doctors
                Company and is an innovator in maximizing health plan and
                physician clinical and financial performance in value-based
                contracting. Through Medical Advantage’s acquisition of
                iHealthSpot, they are also leading providers of website
                development and marketing services
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col
              lg={4}
              className="text-center d-inline-flex align-items-center px-5 py-3 py-lg-0"
            >
              <StaticImage src="../images/wellsky.png" alt="Wellsky" />
            </Col>
            <Col lg={8}>
              <h2>WellSky</h2>
              <p>
                WellSky is a technology company leading the movement for
                intelligent, coordinated care worldwide. WellSky helps
                providers, payers, health systems, and community organizations
                solve tough challenges, improve collaboration for growth,
                harness the power of data analytics, and achieve better outcomes
                by further connecting clinical and social care
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col
              lg={4}
              className="text-center d-inline-flex align-items-center px-5 py-3 py-lg-0"
            >
              <StaticImage
                src="../images/fqhc-associates.png"
                alt="FQHC Associates"
              />
            </Col>
            <Col lg={8}>
              <h2>FQHC.org</h2>
              <p>
                FQHC.org is sponsored by FQHC Associates, a healthcare
                consulting firm that specializes in FQHC programs and
                collaborations. Bridge works with FQHC.org to advance its
                thought leadership in the FQHC market.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col
              lg={4}
              className="text-center d-inline-flex align-items-center px-5 py-3 py-lg-0"
            >
              <StaticImage src="../images/infomedika.png" alt="Infomedika" />
            </Col>
            <Col lg={8}>
              <h2>Infomedika</h2>
              <p>
                Infomedika is vanguard, stability, and commitment in a wide
                variety of industries, pursuing the best attention for patients
                and customers while ensuring efficiency of the revenue cycle
                process and the return of investment. In addition, Informedika
                provides an EHR web version that is a comprehensive application
                focused on the user, enabling quick access to the entire patient
                record.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col
              lg={4}
              className="text-center d-inline-flex align-items-center px-5 py-3 py-lg-0"
            >
              <StaticImage
                src="../images/pai.png"
                alt="Practice Alternatives Inc."
              />
            </Col>
            <Col lg={8}>
              <h2>Practice Alternatives</h2>
              <p>
                Practice Alternatives Inc. began providing business support to
                the medical community in 1982. They offer physicians a
                “one-stop” solution for all their practice related issues
                including billing, EHR, and practice development.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p>
                Interested in partnering with Bridge? We’d like to hear from
                you. <a href="/view-a-demo/">Send us a message!</a>
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default BridgePartners;
